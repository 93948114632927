import { request, proRequest } from '@/utils/request.js'
import { transformProduct, transformComment } from '@/utils/transform';

// 获取案例列表
export function getProducts(params) {
    return request.get('/products', { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformProduct(row))
        return Promise.resolve(temp)
    })
}

// 获取当前公司案例列表
export function getCurrentProducts() {
    return request.get('/user/company/products')
}

// 获取当前公司案例详情
export function getCurrentProductDetail(id) {
    return request.get(`/user/company/products/${id}`)
}

// 获取公司案例列表
export function getCompanyProducts(id) {
    return request.get(`/company/${id}/products`)
}

// 获取案例详情
export function getProduct(id) {
    return request.get(`/products/${id}`).then(({ data }) => {
        return Promise.resolve(transformProduct(data))
    })
}

// 设置案例可见性
export function setProductVisible(id, data) {
    return request.post(`/products/${id}/visible`, data)
}

// 创建案例
export function createProduct(data) {
    return request.post(`/products`, data)
}

// 保存案例
export function saveProduct(data) {
    return request.put(`/products/${data.ID}`, data)
}

// 删除案例
export function deleteProduct(id) {
    return request.delete(`/products/${id}`)
}

// 提交案例
export function evaluateProduct(id) {
    return request.post(`/products/${id}/submit`)
}

// 获取后台案例审核列表
export function getProProducts(params) {
    return proRequest.get(`/products`, { params })
}

// 获取企业案例审核列表
export function getProCompanyProducts(id) {
    return proRequest.get(`/company/${id}/products`)
}

// 获取后台案例详情
export function getProProductDetail(id) {
    return proRequest.get(`/products/${id}`)
}

// 保存后台案例
export function saveProProduct(id, data) {
    return proRequest.put(`/products/${id}`, data)
}

// 审核后台案例
export function evaluteProProduct(id, data) {
    return proRequest.post(`/products/${id}/audit`, data)
}

// 创建产品提问
export function createProductComment(id, data) {
    return request.post(`/products/${id}/comments`, data)
}

// 获取产品提问
export function getProductComment(id, params) {
    return request.get(`/products/${id}/comments`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformComment(row))
        return Promise.resolve(temp)
    })
}
